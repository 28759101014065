import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const TrackPageView = ({ children }) => {
    const location = useLocation () ; 

    useEffect( () => {
        ReactGA.pageview(location.pathname + location.search);
    
    }, [location]);

    return children;
};

export default TrackPageView;

