import HomePage from "../views/homePage/index.jsx";
import Layout from "../layout/Index.jsx";
// import { PayPalPlan1 } from "../components/paypalButtons/PaypalPlan1.jsx";
// import { PricingPage } from "../views/pricingPage/Index.jsx";
import Dashboard from "../views/dashboard/Dashboard.jsx";
import Pay from "../views/subscription/pay";
import PaySuccess from "../views/subscription/paySuccess";
import Subscription from "../views/subscription";
import ContactPage from "../views/contactPage/index.jsx";
import TutorialPage from "../views/tutorialPage/index.jsx";
import PolicyPage from "../views/policyPage/index.jsx";
import TrackPageView from "../components/TrackPageView.jsx";
import RedirectPage from "../views/RedirectPage/index.jsx";

// 路由表
export const home = {
    path: '/',
    element:
        <TrackPageView>
            <Layout>
                <HomePage />
            </Layout>
        </TrackPageView>

}

export const home2 = {
    path: '/home',
    element:
        <TrackPageView>
            <Layout>
                <HomePage />
            </Layout>
        </TrackPageView>
}

export const dashboard = {
    path: '/dashboard',
    element:
        <TrackPageView>
            <Layout>
                <Dashboard />
            </Layout>
        </TrackPageView>
}



export const subscription = {
    path: '/subscription',
    element:
        <TrackPageView>
            <Layout>
                < Subscription />
            </Layout>
        </TrackPageView>
}

export const pay = {
    path: '/pay/:planId',
    element:
        <TrackPageView>
            <Layout>
                <Pay />
            </Layout>
        </TrackPageView>

}

export const paySuccess = {
    path: '/paySuccess',
    element:
        <TrackPageView>
            <Layout>
                <PaySuccess />
            </Layout>
        </TrackPageView>

}

export const contactUs = {
    path: '/contact-us', 
    element:
        <TrackPageView>
            <Layout>
                <ContactPage />
            </Layout>
        </TrackPageView>
}

export const instruction = {
    path: '/instruction', 
    element:
        <TrackPageView>
            <Layout>
                <TutorialPage/>
            </Layout>
        </TrackPageView>
}

export const policy = {
    path: '/private-policy', 
    element:
        <TrackPageView>
            <Layout>
                <PolicyPage/>
            </Layout>
        </TrackPageView>
}

export const reDirect = {
    path: '/redirect', 
    element:
        <TrackPageView>
            
            <RedirectPage/>
            
        </TrackPageView>
}