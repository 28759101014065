import styles from './index.module.css';
const PolicyPage = () => {

    return (
        
        <div className={styles['whole-container']}>
            <h1 className={styles['title']}>
            Private Policy
            </h1>
            <p className={styles['other-content']}>
                Effective from March 8, 2024
            </p>
            <h2>
                Introduction
            </h2>
            <p className={styles['text-content']}>
            Welcome to InterStand. This Privacy Policy informs
             you of our policies regarding the collection, use, 
             and disclosure of personal information when you use our 
             InterStand Chrome Extension and the choices you have 
             associated with that data. We use your data to provide 
             and improve the service. By using the service, 
             you agree to the collection and use of information in 
             accordance with this policy.
            </p>
            <h2>
                Why we collect your information?
            </h2>
            <p>
            We collect information about you for the following 
            general purposes: For registration and to manage your account, 
            including to facilitate your access to and use of our Services; to 
            communicate with you in general, including to provide information 
            about us; to enable us to publish your reviews, forum posts, and 
            other content; to respond to your questions and comments, to prevent 
            potentially prohibited or illegal activities and to enforce our 
            Terms of Use.
            </p>
            <h2>
                Information We Collect
            </h2>
            <p>
                InterStand collects personal information from you when you
                register to access our Services, update your profile, use
                certain Services, contact us with inquiries, or respond to our
                surveys. For example, we may collect your email address,
                 password, and username.    
                When you interact with InterStand through our Services, we 
                receive and store certain data that is personally non-
                identifiable. This information is collected passively using 
                various technologies and cannot be used to specifically identify 
                you at present. InterStand may store this information, or it may 
                be included in databases owned and maintained by our affiliates, 
                agents, or service providers.

                Our Services may use this non-identifiable information and 
                combine it with other data to monitor metrics such as the total 
                number of visitors to our Services, the number of visitors to 
                each page of our sites, and the domain names of our visitors' 
                Internet service providers. We also track how our users interact
                with the InterStand Services.

                In an effort to better understand and serve the users of our 
                Services, InterStand frequently conducts research on customer 
                demographics, interests, and behavior based on the Personal Data 
                and other information provided to us. This research is compiled 
                and analyzed on an aggregate basis. We may share this 
                aggregated, non-identifiable data with our affiliates, agents, 
                and business partners. This type of aggregated and non-
                identifiable information does not personally identify you. 
                Furthermore, InterStand may disclose aggregated user statistics 
                to describe our Services to current and prospective business 
                partners, and to other third parties for lawful purposes.
            </p>

            <h2>
                Personal Data
            </h2>
            <p>
            While using Our Service, We may ask You to provide Us with certain 
            personally identifiable information that can be used to contact or 
            identify You. Personally identifiable information may include, but 
            is not limited to:
            
            Email address
            First name and last name
            Address, State, Province, ZIP/Postal code, City
            Usage and content generation Data
            </p>
            <h2>
                Use of Personal Information
            </h2>
            <p>
            We use your personal information for purposes which may include:
            Provide our customers with a personalized service.
            Processing orders, registrations and inquiries.
            Conducting market research surveys.
            Measuring interest in and improving our website, products and 
            services.
            Providing you with information about products and services we offer.
            Resolving disputes, collecting fees, and troubleshooting problems.
            </p>
            <h2>
                Important Disclosures And Practices
            </h2>
            <p>
            InterStand does not engage in the sale of your information, which we 
            consider a crucial element of our relationship with you. 
            Nevertheless, there are situations in which we might share your 
            Personal Data with certain third parties without additional 
            notice to you, as outlined below:

            Business Transfers: As our business evolves, we may sell or purchase 
            businesses or assets. In the case of a corporate sale, merger, 
            reorganization, dissolution, or similar event, Personal Data may be 
            included among the transferred assets.

            Related Companies: We may share your Personal Data with our 
            affiliate companies for purposes that align with this Privacy 
            Policy.

            Agents, Consultants, and Related Third Parties: Like many companies, 
            InterStand occasionally hires other companies to perform specific 
            business-related functions. Examples might include mailing 
            information, maintaining databases, and processing payments. When we 
            engage another company to perform such tasks, we provide them only 
            with the information they need to carry out their specific service.

            Legal Requirements: InterStand may disclose your Personal Data if 
            required by law or in the good faith belief that such action is 
            necessary to comply with legal obligations, protect and defend the 
            rights or property of InterStand, act in urgent circumstances to 
            protect the safety of users of the Services or the public, or 
            protect against legal liability.
            </p>
            <h2>
                Choice And Opt Out
            </h2>
            <p>
            You have the option to withdraw your consent for the use of your 
            information as described earlier by contacting us at 
            nexaed2023@gmail.com or by adjusting your online preferences for 
            receiving InterStand's marketing and promotional emails. For 
            example, if you prefer not to have your email address or other 
            contact details used to send you promotional offers, or if you 
            decide to unsubscribe from our email newsletter, you can opt out by 
            following the unsubscribe instructions provided in each newsletter 
            or by reaching out to us at nexaed2023@gmail.com.
            </p>
            <h2>
                Access to Your Information
            </h2>
            <p>
            Once you have signed up for our Services, you can modify your 
            personally identifiable information at any time. If you wish to stop 
            receiving emails from us, simply click the “Unsubscribe” link in any 
            email to remove your email address from our mailing list. However, 
            please note that we are unable to modify or delete any of your 
            personally identifiable information that has already been shared 
            with third parties as allowed under this Privacy Policy. If you 
            believe that the personal information we hold is incorrect, or if 
            you want to update, change, or remove your email address or 
            registration/contact information from our records, please reach out 
            to us at nexaed2023@gmail.com. We are committed to reviewing, 
            updating, or removing your information as necessary. Even after 
            removing your information, we may keep your details in our files to 
            resolve disputes, enforce our Terms of Service, or comply with 
            technical and legal obligations that ensure the security, integrity, 
            and operation of our Site and Services.
            </p>
            <h2>
            How InterStand protects your information?
            </h2>
            <p>
            At InterStand, we implement robust security measures for the storage 
            and handling of your personal information to prevent unauthorized 
            access. However, it's important to recognize that no Internet 
            transmission or electronic storage method is completely secure or 
            error-free. We are committed to using commercially viable methods to 
            protect your personal information, but we cannot ensure or warrant 
            its absolute security. Consequently, we advise you to carefully 
            consider the information you choose to transmit to us.
            </p>
            <h2>
                Data Deletion
            </h2>
            <p>
            You have the option to terminate your use of the Services and delete 
            your account at any time by selecting the 'delete account' feature 
            found in the account management area. Upon deletion of 'your 
            account, you will lose access to your Profile, and we will remove
             all data associated with it. We are not liable for any loss of 
             information or content from your Profile following its deletion. It 
             is important to understand that some User Content might persist 
             even after your Profile is deleted, and the User Content License 
             will continue to apply. Deleted accounts cannot be recovered. If 
             you hold a Subscription and decide to delete your account, you will 
             not be entitled to a refund for any unused portion of your 
             subscription.

            Additionally, each email address can be linked to only one 
            InterStand account at a time. Deleting your account does not free up 
            the email address for reuse on a new account. If you wish to use the 
            same email address on another InterStand account, you must first 
            change the email associated with your current account in the 
            settings before deletion. After changing the email, you can delete 
            the account and then use your original email address on a different 
            InterStand account. For security measures, paid subscribers are 
            required to cancel their subscriptions prior to deleting their 
            InterStand accounts.
            </p>

            <h2>
                How to contact us? 
            </h2>
            <p>
            Should you have any questions regarding InterStand's Privacy Policy 
            or our data handling practices, please do not hesitate to reach out 
            to us at nexaed2023@gmail.com.
            </p>
            <h2>
                Modification of The Privacy Policy
            </h2>
            <p>
            The Services and our business operations may evolve over time. 
            Consequently, it may sometimes be necessary for InterStand to make 
            adjustments to this Privacy Policy. InterStand retains the right to 
            update or modify this Privacy Policy at any time without prior 
            notice. We encourage you to periodically review this policy, 
            especially before submitting any Personal Data. This Privacy Policy 
            was last updated on the EFFECTIVE DATE noted on the top of this 
            page. By continuing to use the Services after any changes or updates 
            to this Privacy Policy, you are expressing your agreement with the 
            terms of the revised Privacy Policy.
            </p>



            
        </div>
    )

}


export default PolicyPage