import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { router } from "./router/index";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import './index.css';
// 集成google analytics 
import ReactGA from 'react-ga';

const TRACKING_ID = "G-T38VT3DK3R"; 
// 初始化 React GA 
ReactGA.initialize(TRACKING_ID);

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);
root.render(<React.StrictMode><App /></React.StrictMode>);

