import service from "../utils/request";

// google Login // 暂时作废 ---- 需要转换成json.stringify
export const goolgeLoginApi = (crendential)=> {
    return service.post('api/auth/google', crendential)
} 


// 发送请求
export const getUserAuth = ()=>{
    return service.get('api/auth/me')
}