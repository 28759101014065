import styles from './Index.module.css';
// import TopPostBadge from '../../img/top-post-badge.svg'
import GreenHero from '../../img/hero2.png'
import ChromeLogo from '../../img/Google_Chrome-Logo.png'
import InstructionVideo from '../../video/instruction.mp4'
import ExeFile from '../../softwares/InterStand.exe'
import { useState, useEffect, useRef } from 'react';
import { DiscordFilled, FacebookFilled, WindowsOutlined} from '@ant-design/icons';


const HomePage = () => {

    const [texts] = useState(['Working', 'Learning', 'Communicate',
        'Writing', 'Everything']);
    const textsIndex = useRef(0);
    const [text, setText] = useState('');

    //useEffect 首页挂载的时候执行
    useEffect(() => {
        const incrementText = (originalText) => {
            let index = 0;
            const timer = setInterval(() => {
                // slice 截取
                const partialText = originalText.slice(0, index + 1);
                setText(partialText);
                if (index === originalText.length - 1) {
                    clearInterval(timer);
                    setTimeout(() => {
                        decrementText(originalText);
                    }, 1200); // 停顿1.2秒后再执行 decrementText
                }
                index++
            }, 100)
        }

        const decrementText = (originalText) => {
            let index = originalText.length;
            const timer = setInterval(() => {
                // 注意这里是正向递减index
                index--;
                const partialText = originalText.slice(0, index);
                setText(partialText);
                if (index === 0) {
                    clearInterval(timer)
                    // 更新textsIndex以指向下一个文本
                    textsIndex.current = textsIndex.current === 
                    texts.length - 1 ? 0 : textsIndex.current + 1;
                    // 给下一轮动画一点时间启动
                    setTimeout(() => {
                        incrementText(texts[textsIndex.current]);
                    }, 1200); // 继续下一个文本的展示前稍作停顿
                }
            }, 60);
        }
        incrementText(texts[textsIndex.current])
    }, [texts])



    return (
        // 整个container 
        <div>
            <div className={styles['page-one']}>
                <div className={styles.container}>
                    {/* 1. 区域1  */}
                    <div className={styles['area-top']}>
                        {/* 1.1 文字部分 */}
                        <div className={styles['top-box-left']}>
                            <div className={styles['decor-box']}>
                                {/* <img src={TopPostBadge} 
                                    alt="top post badge" /> */}
                            </div>
                            <div className={styles['top-left-texts']}>
                                {/* 这是span 和 div 一样吗 */}
                                
                                <div className={styles.text}> One Click AI Translation & Text Explanation</div>
                                <div className=''>
                                    {/* In <span className={styles["blinking-text"]}>
                                        {text}</span>
                                    <span className={styles["blinking-line"]}>|</span> */}
                                </div>
                                <div className={styles.text}>With </div>
                                <div className={styles.textSpecial}>
                                    InterStand </div>
                                

                            </div>
                            <div className={styles['normal-text-box']}>
                            Unlock Instant Understanding: One Click, Infinite Possibilities—Translate and Explain Text Anywhere on Windows!
                            </div>
                            <div className={styles['button-box']}>
                                <a 
    href={ExeFile}
                                target="_blank" rel="noopener noreferrer">
                                    <button className={styles['button']}>
                                        Try For Free With Windows (beta)
                                        <WindowsOutlined 
                                        title="Download the Windows version"
                                        className={styles['icon']}/>    
                                    </button>    
                                </a>

                                <a href="https://discord.gg/WmSGcQMyFG" 
                                target="_blank" rel="noopener noreferrer" download>
                                    <DiscordFilled 
                                    title="Join Our Discord Community and 
                                    Contact Us to Get 50 Free usage tokens!!"
                                    className={styles['icon']}/>
                                </a>
                                
                                <a href="https://www.facebook.com/InterStand2024/" 
                                target="_blank" rel="noopener noreferrer">
                                    <FacebookFilled 
                                    title="Join Facebook Community"
                                    className={styles['icon']}/>
                                </a> 
                            </div>
                            <a 
 href="https://chromewebstore.google.com/detail/mdefchfdgegpajbaiegdlpggdokdgnii?hl=en&authuser=0" 
                            target="_blank" rel="noopener noreferrer">
                            <button className={styles['button']}>
                                Try For Free With Chrome 
                                <img src={ChromeLogo} alt="Chrome Logo" 
                                className={styles['chrome-logo']} />
                            </button>
                            </a>
                            


                        </div>
                        {/* 1.2 图片部分 */}
                        <div className={`${styles['top-box-right']}
                                        ${styles['top-right-hero']}`}>
                            <img className={styles["top-right-hero"]}
                                src={GreenHero} alt="top right green hero" />
                        </div>
                    </div>
                    
                </div>
            </div>


            {/* page two --- Instruction  */}
            <div className={styles['page-two']}>
                <div className={styles.container}>
                    {/* 2. 区域2  */}
                    <div className={styles['area-top']}>
                        {/* 2.1图片部分 */}
                        <div className={`${styles['top-box-pic']}
                                        ${styles['top-left-hero']}`}>
                            <video className={styles["top-right-hero"]} controls>
                                <source src={InstructionVideo} type="video/mp4" />
                            </video>
                        </div>


                        {/* 2.2 文字部分 */}
                        <div className={styles['top-box-text']}>
                            <div className={styles['decor-box']}>
                                {/* <img src={TopPostBadge} 
                                    alt="top post badge" /> */}
                            </div>
                            <div className={styles['top-right-texts']}>
                                {/* 这是span 和 div 一样吗 */}
                    
                                <div className={styles.text}>Easy To Use For Achieving The Best Results In Text Comprehension</div>
                                {/* <div className={styles.text}>With </div> */}
                                {/* <div className={styles.textSpecial}>
                                    InterStand </div> */}
                            </div>
                            {/* <div className={styles['normal-text-box']}>
                            InterStand products go beyond translation to 
                            swiftly and effectively deepen your 
                            understanding of languages and emerging 
                            concepts in an AI-driven world, 
                            enhancing your ability to keep pace with
                            technological advancement.
                            </div> */}
                            <div className={styles['button-box']}>
                            <a 
 href={ExeFile}
                            target="_blank" rel="noopener noreferrer" download>
                                <button className={styles['button']}>
                                    Try For Free With Windows (beta)
                                    <WindowsOutlined 
                                    title="Download the Windows version"
                                    className={styles['icon']}/>  
                                    
                                    
                                </button>
                            </a>
                                <a href="https://discord.gg/WmSGcQMyFG" 
                                target="_blank" rel="noopener noreferrer">
                                    <DiscordFilled 
                                    title="Join Our Discord Community and 
                                    Contact Us to Get 50 Free usage tokens!!"
                                    className={styles['icon']}/>
                                </a>
                                
                                <a href="https://www.facebook.com/InterStand2024/" 
                                target="_blank" rel="noopener noreferrer">
                                    <FacebookFilled 
                                    title="Join Facebook Community"
                                    className={styles['icon']}/>
                                </a>
                            </div>
                            <a 
 href="https://chromewebstore.google.com/detail/mdefchfdgegpajbaiegdlpggdokdgnii?hl=en&authuser=0" 
                            target="_blank" rel="noopener noreferrer">
                            <button className={styles['button']}>
                                Try For Free With Chrome 
                                <img src={ChromeLogo} alt="Chrome Logo" 
                                className={styles['chrome-logo']} />
                            </button>
                            </a>
                        </div>

                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default HomePage