import Nav from "../components/Nav";
import styles from './Index.module.css';

const Layout = ({ children }) => {
    return (
        <div className={styles['layout']}>
            <Nav />
            {/* 页面 */}
            {children}
            <div className={styles.bottom} >© 2024 NexaEd LLC. All rights reserved.</div>
        </div>
    )
}

export default Layout