export const BASE_URL = {
    dev: 'http://127.0.0.1:3333/',
    // test: 'http://18.217.231.195:3333/'
    test: 'https://interstandapp.com:3335/',
    production: 'https://api.interstandus.com/',
}[process.env.REACT_APP_ENV]

// console.log ('Current API URL:', BASE_URL);

export const BASE_GOOGLE_CLIENT_ID = {
    dev: '1031687499650-i5fvpu8b8v0pkad2qae2ta38iahldoh1.apps.googleusercontent.com',
    test: '1031687499650-i5fvpu8b8v0pkad2qae2ta38iahldoh1.apps.googleusercontent.com',
    production: '174386711146-4cn5ci2n25ui8btr16ok4gau3n106i63.apps.googleusercontent.com'
}[process.env.REACT_APP_ENV]

export const BASE_PAYPAL_CLIENT_ID = {
    dev: 'AVC8623aSQoF8ildqZ2GiWmcSPoVsdGztVVIND4OK2KkDn4a-lpZn8ytf8FeRM8TruWtKCb4geHI3tWD',
    // dev: 'AeglK_1YxBasWvxpaByOSb6rSsWLJDNNZIhGae0wLlAnBCdI7TPih-jXgtg_brewB4cFPs4Rnn_duQQN',
    test: 'AVC8623aSQoF8ildqZ2GiWmcSPoVsdGztVVIND4OK2KkDn4a-lpZn8ytf8FeRM8TruWtKCb4geHI3tWD',
    production: 'AQJhUTz1CNDBNZIk864PpfRQgQy5TfV9TbFKQe4EhRA4PQtRtjdlvkFmLOWQp3_2wbERvIp7loSUPyow',
}[process.env.REACT_APP_ENV]

// export const BASE_PAYPAL_PLAN1_ID = {
//     dev: 'P-5WG60615NX743391XMXMUHBA',
//     test: 'P-5WG60615NX743391XMXMUHBA',
//     production: 'P-5WG60615NX743391XMXMUHBA'
// }[process.env.REACT_APP_ENV]