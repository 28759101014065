import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { BASE_PAYPAL_CLIENT_ID } from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import { createSubscriptionApi } from "../../api/subscription";
import { useRef, useState, useEffect } from "react";
import { getSubscriptionInfoApi } from "../../api/subscription";
import { Spin } from 'antd';
import styles from './pay.module.css';

const initialOptions = {
    clientId: BASE_PAYPAL_CLIENT_ID,
    vault: "true",
    intent: "subscription"
}

const Pay = () => {
    const { planId } = useParams();
    const subscription_id = useRef()
    const [loadData, setLoadData] = useState(false)
    const timer = useRef()

    useEffect(() => {
        return timer.current && clearTimeout(timer.current)
    }, [])

    const createSubscription = async () => {
        const res = await createSubscriptionApi({
            planId
        })
        if (res.data.code === 200) {
            subscription_id.current = res.data.data.id
            return subscription_id.current
        }
    }

    const nav = useNavigate()
    const lunxun = () => {
        timer.current = setTimeout(async () => {
            const res = await getSubscriptionInfoApi()
            const { code, data } = res.data
            if (code === 200 && data && data.subscription_id === subscription_id.current) {
                setLoadData(false)
                nav('/paySuccess', { replace: true })
            } else {
                lunxun()
            }
        }, 1500)
    }

    const onApprove = () => {
        setLoadData(true)
        lunxun()
    }

    return <>
        {
            loadData ? <div>
                <Spin />
                {/* 数据更新同步中，请稍后... */}
                Data synchronization in progress, 
                please wait... Don't close the window
            </div> :
            <div className={styles['paypal-button-container']}>
                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons style={{
                        shape: 'pill',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'paypal',
                    }}
                        createSubscription={createSubscription}
                        onApprove={onApprove}
                    />
                </PayPalScriptProvider>
            </div>
        }
    </>
}

export default Pay