import { createSlice } from '@reduxjs/toolkit';

// 初始状态，所有订阅计划都未被订阅
const initialState = {
    plan1Subscribed: false,
    // plan2Subscribed: false,
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
      // 更新订阅计划1的状态
      subscribePlan1: (state) => {
        state.plan1Subscribed = true;
      },
      resetSubscription: () => {
        return initialState; // 重置为初始状态
      },
    //   // 更新订阅计划2的状态
    //   subscribePlan2: (state) => {
    //     state.plan2Subscribed = true;
    //   },
    },
  });
  
  // 导出每个 action creator
  export const { subscribePlan1, resetSubscription } = subscriptionSlice.actions;
  
  // 导出 reducer
  export default subscriptionSlice.reducer;