// 封装请求
import axios from "axios";
import {
    BASE_URL,
} from "../config";

// 公共地址 传参 尽可能还原axios本来的配置

const service = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
});


// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // console.log(1, config);
    // 在发送请求之前做些什么
    if (localStorage.getItem('jwt')) {
        // 发送token到请求头
        config.headers.token = localStorage.getItem('jwt')
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

export default service