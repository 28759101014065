import { createBrowserRouter } from "react-router-dom";
import { home, home2, subscription, dashboard,
     pay, paySuccess, contactUs, instruction,policy, reDirect} from "./routers";

export const router = createBrowserRouter([
    home,
    home2,
    subscription,
    dashboard,
    pay,
    paySuccess,
    contactUs,
    instruction,
    policy,
    reDirect
])