import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {}, // 初始状态为空对象
    // isLoggedIn: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
    //   state.isLoggedIn = true;
    },
    clearUser: (state) => {
      state.userInfo = {};
    //   state.isLoggedIn = false;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
