
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from "react-redux";
import { loggedIn } from '../store/loginSlice';
import {setUser} from '../store/userSlice';
import { getUserAuth } from '../api/auth';
import {
    BASE_URL,
    BASE_GOOGLE_CLIENT_ID,
} from "../config";


export const GoogleSignIn = () => {
    //useSelector来访问Redux store中的登录状态
    const dispatch = useDispatch();

    const handleLoginSucess = async ({ credential }) => {
        // console.log(credential);
        try {
            const res = await
                fetch(`${BASE_URL}api/auth/google/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ credential })
                });
            // 处理响应
            if (res.ok) {
                const res_data = await res.json();
                // console.log("res data", res_data);
                // 存储
                const { jwtToken } = res_data.data;
                // console.log("jwtToken", jwtToken);
                localStorage.setItem('jwt', jwtToken);

                //2)状态改变： 登入模式 
                dispatch(loggedIn(jwtToken));

                // 拿到用户信息 ；
                // 调用getUserAuth并更新Redux Store
                const userInfo = await getUserAuth();
                dispatch(setUser(userInfo.data)); // 假设userInfo是API响应的格式
            }

        } catch (error) {
            console.error("Error during login: ", error);
        }
    }

    return (
        <div>
            <GoogleOAuthProvider
                clientId={`${BASE_GOOGLE_CLIENT_ID}`}>
                <GoogleLogin
                    type='icon'
                    size='large'
                    shape='circle'

                    // key={loginKey} // 使用 key 强制刷新组件 ***
                    onSuccess={handleLoginSucess}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </GoogleOAuthProvider>
        </div>
    )
}