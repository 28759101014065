import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    isLoggedIn: false,
    jwtToken: null,
}




export const loginSlice = createSlice ({
    name: 'login',
    initialState,
    reducers: {
        //actions 
        loggedIn: (state, action)=> {
            // 可以写成赋值形式，但是是immutable 
            state.isLoggedIn = true;
            state.jwtToken = action.payload; // ???
        },
        loggedOut : (state,action) => {
            state.isLoggedIn = false;
            state.jwtToken = null;
        }
    }
}) 


export const { loggedIn, loggedOut } = loginSlice.actions; 

export default loginSlice.reducer;
