import service from "../utils/request";

export const getPlanListApi = () => {
    return service.post('api/subscriptionv2/getPlanList')
}

export const getSubscriptionInfoApi = () => {
    return service.post('api/subscriptionv2/getSubscriptionInfo')
}

export const createSubscriptionApi = (data) => {
    return service.post('api/subscriptionv2/createSubscription', data)
}

export const cancelSubscriptionApi = (data) => {
    return service.post('api/subscriptionv2/cancelSubscription', data)
}