import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import subscriptionReducer from './subscriptionSlice';
import userReducer from './userSlice';
import storage from 'redux-persist/lib/storage'; // 默认使用localStorage
import { persistStore, persistReducer,
    FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';


// combine reducers
const rootReducer = combineReducers({
    login: loginReducer,
    subscription: subscriptionReducer,
    user: userReducer,
    // config: {

    // }
});

// 创建持久化配置
const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['login', 'user'], // 你可以选择只持久化部分reducer
    // blacklist: ['subscription'], // 或者指定不被持久化的reducer
};

// 创建持久化的根reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    // reducer: rootReducer,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // Optionally, you can also ignore other paths:
            // ignoredPaths: ['some.nested.path'],
        },
    }),
})

// 创建持久化的store
export const persistor = persistStore(store);



