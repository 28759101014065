import { GoogleSignIn } from "../../components/GoogleSignIn";
import { googleLogout } from '@react-oauth/google';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './dashboard.module.css';
import { useSelector, useDispatch } from "react-redux";
import { loggedIn, loggedOut } from "../../store/loginSlice";
import { setUser } from "../../store/userSlice";
import { resetSubscription } from "../../store/subscriptionSlice";
import { getUserAuth } from "../../api/auth";


// 使用useEffect 钩子来检测 Local storage 中 jwt 的变化 

function Dashboard() {
    //useSelector来访问Redux store中的登录状态
    const userInfo = useSelector((state) => state.user.userInfo);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const dispatch = useDispatch();
    const location = useLocation(); // 获取当前页面的URL和查询参数

    // 拿到token 
    const jwtToken = localStorage.getItem('jwt');
    // 桌面链接url
    const desktopUrl = `interstand:token=${jwtToken}`

    // 用来给桌面版本探索用的
    const searchParams = new URLSearchParams(location.search);


    const handleLogout = useCallback(() => {
        localStorage.removeItem('jwt');// 需要增加逻辑， jwt 被删除， 登出
        googleLogout();// 应该清除了Goolge的会话?
        dispatch(loggedOut());
        dispatch(resetSubscription());
    }, [dispatch]);

    // 每次页面刷新调用 getUserAuth 并更新 Redux store 
    useEffect(()=> {
        async function fetchUserData() {
            const userInfo = await getUserAuth();
            dispatch(setUser(userInfo.data));
        }

        fetchUserData();

    }, [dispatch]); // 确保当 dispatch 没有改变时，不会重复调用

    useEffect(() => {
        // 检查localStorage中的jwt token
        const jwtToken = localStorage.getItem('jwt');
        if (jwtToken) {
            dispatch(loggedIn(jwtToken));
        } else {
            dispatch(loggedOut());
            dispatch(resetSubscription());
        }
    }, [dispatch]);

    // 另一个 useEffect 根据 url 当前的参数来改变
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        
        if (searchParams.has('forceLogin')) {
            handleLogout(); // 如果存在 forceLogin 参数，则执行退出登录操作
        }
        
    }, [location.search, handleLogout]); // 当 URL 查询参数发生变化时触发

    return (
        <div className={styles.container}>
            {isLoggedIn ? (
                <div className={styles.loginWithThirdParty}>
                    <div className={styles.contentText} >
                        Welcome, {userInfo.username}!
                    </div>
                    <div className={styles.planBox}>
                        {/* <div className={styles.planText}>
                            Current Plan: {userInfo.currentPlan}
                            {!userInfo.currentPlan ? (
                                <a href="/subscriptionList" >
                                    Subscribe a Plan</a>
                            ) : (
                                <div></div>
                                // <a href="/subscriptionList" ></a>
                            )}
                        </div> */}
                        <div className={styles.planText}>
                            Current Points:  {userInfo.currentPoints}
                        </div>

                        <a href="/subscription" 
                        className={styles.specialText}>
                            Manage Account & Billing
                        </a>
                    </div>
                    <div className={styles.logoutBox} >
                        {searchParams.has('loginType') ? (
                            <div>
                                <a  href= { desktopUrl }
                                    target="_blank" rel="noopener noreferrer">
                                    <button className={styles['button']}>
                                        Open the desktop app
                                    </button>

                                </a>
                            </div>
                        ):(
                            <button className={styles.logoutButton}
                            onClick={handleLogout}>Log Out
                            </button>

                        )}
                        
                    </div>

                </div>

            ) : (
                <div className={styles.loginWithThirdParty}>
                    <div className={styles.loginTittle}>
                        Login With
                    </div>
                    <div className={styles.loginButton}>
                        <GoogleSignIn />
                    </div>
                    <div className={styles['little-text-box']}>
                        <p className={styles['little-text']}>
                        By signing up, you agree to the Terms and Conditions
                         and Privacy Policy. 
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Dashboard;