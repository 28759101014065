import styles from './index.module.css';



const RedirectPage = () => {

    return (
        
        <div className={styles['general-container']} >

            <div className={styles['info-container']} >
                <div className={styles['temp-notes']}>
                Welcome! 
                </div>
                

                <div className={styles['pic-box']}>
                    
                    <a  href="" 
                        target="_blank" rel="noopener noreferrer">
                        <button className={styles['button']}>
                            Open the desktop app
                        </button>

                    </a>
                </div>
            </div>
        </div>
    )

}


export default RedirectPage