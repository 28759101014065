import { useEffect, useState, useCallback, useRef } from 'react';
import { getPlanListApi, getSubscriptionInfoApi, cancelSubscriptionApi } from "../../api/subscription";
import { useNavigate } from 'react-router-dom';
import { Button, Spin, Space, message, Modal } from "antd";
import { FireFilled } from "@ant-design/icons";
import styles from "./index.module.css";

const SubscriptionList = () => {
    const [planList, setPlanList] = useState([])
    const [subscriptionInfo, updateSubscriptionInfo] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getPlanList = async () => {
            // 获得planList 
            const planList = await getPlanListApi()
            const { code, data } = planList.data
            if (code === 200) {
                setPlanList(data)
            }
        }
        getPlanList()
    }, [])

    useEffect(() => {
        // 获得用户订阅信息
        const getSubscriptionInfo = async () => {
            try {
                const subscriptionInfo = await getSubscriptionInfoApi()
                const { code, data } = subscriptionInfo.data
                if (code === 200 && data) {
                    updateSubscriptionInfo(data)
                }
            } finally {
                setLoading(false)
            }
        }
        getSubscriptionInfo()
    }, [])

    const nav = useNavigate()
    const toPayPage = (plan_id) => {
        nav(`/pay/${plan_id}`)
    }

    const subscribed = useCallback((plan_id) => {
        return subscriptionInfo.plan_id === plan_id && subscriptionInfo.plan_status === 'successful'
    }, [subscriptionInfo])



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [beforeSubscriptionName, setBeforeSubscriptionName] = useState('');
    const [afterSubscriptionName, setAfterSubscriptionName] = useState('');
    const clickItem = useRef({});

    useEffect(() => {
        if (!isModalOpen) {
            clickItem.current = {}
            setBeforeSubscriptionName('')
            setAfterSubscriptionName('')
        }
    }, [isModalOpen])

    const handlePay = (item) => {
        clickItem.current = item
        if (subscriptionInfo.plan_status === 'successful') {
            setAfterSubscriptionName(item.name)
            const curPlan = planList.find(item => {
                return item.paypal_plan_id === subscriptionInfo.plan_id
            })
            setBeforeSubscriptionName(curPlan.name)
            setIsModalOpen(true)
        } else {
            toPayPage(item.paypal_plan_id)
        }
    }
    const handleOk = () => {
        setIsModalOpen(false);
        toPayPage(clickItem.current.paypal_plan_id)
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [cancelSubscriptionName, setCancelSubscriptionName] = useState('');

    useEffect(() => {
        if (!isModalOpen2) {
            setCancelSubscriptionName('')
        }
    }, [isModalOpen2])

    async function hanldeCancelPlan(item) {
        setCancelSubscriptionName(item.name)
        setIsModalOpen2(true)
    }

    const handleOk2 = async () => {
        setIsModalOpen2(false);
        setLoading(true)
        try {
            const res = await cancelSubscriptionApi()
            const { code } = res.data
            if (code === 200) {
                message.success('Successfully Canceled！');
                updateSubscriptionInfo(pre => {
                    return {
                        ...pre,
                        plan_status: 'cancelled'
                    }
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    }

    return <Spin tip="Loading..." spinning={loading}>
        <div className={styles['plans-container']}>
            {
                planList.map(item => {
                    return <div className={styles['plan-items']} key={item.id} >
                        <div className={styles['plan-name']}>{item.name}</div>
                        <div className={styles['plan-desc']}>{item.desc}</div>
                        <div className={styles['plan-points']}>{item.billing_cycle === 'monthly' ? 'Every Month ' : 'Every year '}Free Queries <b>{item.plan_points}</b></div>
                        <div className={styles['plan-price']}>
                            $ {item.price}
                            {item.billing_cycle === 'monthly' ?
                         ' / Month ' : ' / Year '}
                        </div>
                        {item.hot ? <FireFilled className={styles['plan-hot']} /> : null}
                        <div className={styles['footer']}>
                            {
                                subscribed(item.paypal_plan_id) ?
                                    <Button style={{ width: 130, fontWeight:'bolder' }} type='primary' onClick={() => { hanldeCancelPlan(item) }}>Cancel </Button> :
                                    <Button style={{ width: 130 , fontWeight:'bolder' }} type="primary" disabled={subscribed(item.paypal_plan_id)} onClick={() => { handlePay(item) }}>Subscribe</Button>
                            }
                        </div>
                    </div>
                })
            }
        </div>


        <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <h3>Are you sure change {beforeSubscriptionName}, to {afterSubscriptionName}?</h3>
        </Modal>
        <Modal title="" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
            <h3>Confirm cancel {cancelSubscriptionName}?</h3>
        </Modal>
    </Spin >
}

export default SubscriptionList