import styles from './index.module.css';
import InstructionImg from '../../img/instruction1.png'

const TutorialPage = () => {

    return (
        
        <div className={styles['whole-container']}>

            <h2>
                Resources Links :
            </h2>
            <p className={styles['normal-text']}>
                <a 
                href="https://www.youtube.com/channel/UCWgsfNArdBOf9ibAl2MJN-Q" 
                                    target="_blank" rel="noopener noreferrer">
                    Our Youtube Chanel
                </a>

            </p>

            <p className={styles['normal-text']}>
                <a 
                href="https://www.notion.so/interstand/InterStand-Welcome-Wiki-15cdb7a8e93d4f8c9f243bb6a1fc4ba3?pvs=4" 
                                    target="_blank" rel="noopener noreferrer">
                    InterStand Wiki (Notion)
                </a>

            </p>

            <p className={styles['normal-text']}>
                <a 
                href="https://chromewebstore.google.com/detail/interstand/mdefchfdgegpajbaiegdlpggdokdgnii?hl=en&authuser=0" 
                                    target="_blank" rel="noopener noreferrer">
                    Find us on Chrome webstore
                </a>

            

            </p>
            <h2 className={styles['block']}>
                General Intructions:
            </h2>
            <p>
                <img src={InstructionImg} alt="instruction" className={styles['img']} />
            </p>
            
        </div>
    )

}


export default TutorialPage